import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Invoice>();
const slice = createSlice({
  name: "invoices",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getInvoicesSuccess(state, action: PayloadAction<ResultList<Invoice>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      adapter.setAll(state, data);
    },
    addInvoiceSuccess(state, action: PayloadAction<Result<Invoice>>) {
      state.status = "success";
      adapter.upsertOne(state, action.payload.data);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectInvoices = createSelector(
  (state: RootState) => state.invoices,
  (invoices) => selectAll(invoices)
);

export const getInvoices: AsyncAction = (page: number) => {
  return async (dispatch, getState) => {
    const { currentPage } = getState().invoices;
    if (currentPage === page) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<Invoice>>("/invoices", { params: { page } });
      return dispatch(actions.getInvoicesSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const showInvoice: AsyncAction = (id: number) => {
  return async (dispatch, getState) => {
    if (getState().invoices.entities[id] !== undefined) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, Result<Invoice>>(`/invoices/${id}`);
      return dispatch(actions.addInvoiceSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
