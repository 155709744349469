import { Icon } from "@iconify/react";
import { FC, useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import { Box, Button, Divider, MenuItem, Typography } from "@mui/material";
// routes
// hooks
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import useIsMountedRef from "../hooks/useIsMountedRef";
// components
import { MIconButton } from "../components/@material-extend";
import MyAvatar from "../components/MyAvatar";
import MenuPopover from "../components/MenuPopover";
import { logout } from "../redux/slices/client";
import { PATH_DASHBOARD } from "../routes/paths";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "الرئيسية",
    icon: homeFill,
    linkTo: PATH_DASHBOARD.root,
  },
  {
    label: "الملف الشخصي",
    icon: personFill,
    linkTo: PATH_DASHBOARD.profile,
  },
  {
    label: "الإعدادات",
    icon: settings2Fill,
    // TODO: use correct path
    linkTo: PATH_DASHBOARD.root,
  },
];

// ----------------------------------------------------------------------

const AccountPopover: FC = () => {
  const anchorRef = useRef(null);
  const isMountedRef = useIsMountedRef();
  const dispatch = useAppDispatch();
  const { current, status } = useAppSelector((state) => state.client);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logout());
      if (isMountedRef.current) handleClose();
    } catch (error) {}
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          {status === "pending" && <Typography variant="body2">جاري التحميل...</Typography>}
          <Typography variant="subtitle1" noWrap>
            {current?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {current?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            تسجيل الخروج
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
