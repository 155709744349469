import { FC, ReactElement, useState } from "react";
import { Icon } from "@iconify/react";
import { matchPath, NavLink as RouterLink, useLocation } from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// material
import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  Box,
  BoxProps,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { StyledComponent } from "@emotion/styled";
import { useAppDispatch } from "../hooks/redux";
import { showWhatsappMsg } from "../redux/slices/whatsapp";

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
  })
);

const ListItemStyle: StyledComponent<any> = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// reuse ListItemStyle for Button
const NavButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.body2,
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  height: 48,
  width: "100%",
  justifyContent: "start",
}));

// ----------------------------------------------------------------------

type NavItem = {
  title: string;
  path: string;
  icon?: ReactElement;
  info?: string;
  children?: Omit<NavItem, "children">[];
};
type NavItemProps = {
  item: NavItem;
  isShow: boolean;
};
const NavItem: FC<NavItemProps> = ({ item, isShow }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item: any) => {
                const { title, path } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "text.disabled",
                          transition: (theme) => theme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
};

type NavSectionProps = BoxProps & { isShow: boolean; navConfig: { subheader?: string; items: NavItem[] }[] };
const NavSection: FC<NavSectionProps> = ({ navConfig, isShow = true, ...other }) => {
  const dispatch = useAppDispatch();
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
      {/* <NavButtonStyle
        onClick={() => dispatch(showWhatsappMsg())}
        startIcon={
          <Icon icon="jam:message-f" />
        }
        children={isShow && "إرسال Whatsapp"}
      /> */}
      <Button
        onClick={() => dispatch(showWhatsappMsg())}
        startIcon={
          <Box component={Icon} icon="jam:message-f"/>
        }
        sx={{
          gap: 1,
          fontSize: ".875rem",
          paddingLeft: 5,
          paddingRight: 2.5,
          color: "text.secondary",
          height: 48,
          width: "100%",
          justifyContent: "start",
          alignItems: "center",
          fontWeight: "normal",
          "> span": {
            marginLeft: 0
          }
        }}
        children={isShow && "إرسال Whatsapp"}
      />
    </Box>
  );
};

export default NavSection;
