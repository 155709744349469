import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "drawer",
  initialState: {
    collapseClick: false,
    collapseHover: false,
  },
  reducers: {
    toggleCollapse(state) {
      state.collapseClick = !state.collapseClick;
    },
    hoverEnter(state) {
      state.collapseHover = true;
    },
    hoverLeave(state) {
      state.collapseHover = false;
    },
  },
});

export const { toggleCollapse, hoverEnter, hoverLeave } = slice.actions;
export default slice.reducer;
