import { forwardRef } from "react";
// material
import { Avatar, AvatarProps, useTheme } from "@mui/material";

// ----------------------------------------------------------------------

type Props = AvatarProps & {
  color: "default" | "primary" | "secondary" | "info" | "success" | "warning" | "error";
};
const MAvatar = forwardRef<HTMLDivElement, Props>(({ color = "default", sx, children, ...other }, ref) => {
  const theme = useTheme();

  if (color === "default") {
    return (
      <Avatar ref={ref} sx={sx} {...other}>
        {children}
      </Avatar>
    );
  }

  return (
    <Avatar
      ref={ref}
      sx={{
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette[color].contrastText,
        backgroundColor: theme.palette[color].main,
        ...sx,
      }}
      {...other}
    >
      {children}
    </Avatar>
  );
});

export default MAvatar;
