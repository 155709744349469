const path = (root: string, sublink: string) => root + sublink;

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard/";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
};

export const PATH_PAGE = { page404: "/404", page500: "/500" };

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  chat: {
    root: path(ROOTS_DASHBOARD, "chats"),
    new: path(ROOTS_DASHBOARD, "chats/new"),
  },
  instances: path(ROOTS_DASHBOARD, "instances"),
  invoices: path(ROOTS_DASHBOARD, "invoices"),
  employees: path(ROOTS_DASHBOARD, "employees"),
  replies: path(ROOTS_DASHBOARD, "replies"),
  chatBots: path(ROOTS_DASHBOARD, "bots-instances"),
  profile: path(ROOTS_DASHBOARD, "profile"),
  categories: path(ROOTS_DASHBOARD, "categories"),
  groups: path(ROOTS_DASHBOARD, "contact-groups"),
  contacts: path(ROOTS_DASHBOARD, "contacts"),
};
