import { FC, useMemo } from "react";
import { MAvatar } from "./@material-extend";
import createAvatar from "../utils/createAvatar";
import { useAppSelector } from "../hooks/redux";
import { AvatarProps } from "@mui/material";

// ----------------------------------------------------------------------

const MyAvatar: FC<AvatarProps> = ({ ...other }) => {
  const client = useAppSelector((state) => state.client.current);

  const avatar = useMemo(() => {
    if (client) return createAvatar(client.name);
  }, [client]);

  return (
    <MAvatar color={avatar?.color as any} {...other}>
      {avatar?.name}
    </MAvatar>
  );
};

export default MyAvatar;
