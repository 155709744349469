import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Reply>();
const slice = createSlice({
  name: "replies",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getRepliesSuccess(state, action: PayloadAction<ResultList<Reply>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      adapter.setAll(state, data);
    },
    addReplySuccess(state, action: PayloadAction<Result<Reply>>) {
      state.status = "success";
      adapter.upsertOne(state, action.payload.data);
    },
    deleteReplySuccess(state, action: PayloadAction<number>) {
      adapter.removeOne(state, action.payload);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectReplies = createSelector(
  (state: RootState) => state.replies,
  (replies) => selectAll(replies)
);

export const getReplies: AsyncAction = (page: number) => {
  return async (dispatch, getState) => {
    if (getState().replies.currentPage === page) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<Reply>>("/quick-replies", { params: { page } });
      return dispatch(actions.getRepliesSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const showReply: AsyncAction = (id: number) => {
  return async (dispatch, getState) => {
    if (getState().replies.entities[id] !== undefined) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, Result<Reply>>(`/quick-replies/${id}`);
      return dispatch(actions.addReplySuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const storeReply: AsyncAction = (data: StoreReply) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.post<any, Result<Reply>>("/quick-replies/", data);
      return dispatch(actions.addReplySuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const updateReply: AsyncAction = ({ id, ...patch }: StoreReply & Pick<Reply, "id">) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.put<any, Result<Reply>>(`/quick-replies/${id}`, patch);
      return dispatch(actions.addReplySuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const deleteReply: AsyncAction = (replyId: number) => {
  return async (dispatch, getState) => {
    const item = getState().replies.entities[replyId];
    if (item === undefined) return;

    dispatch(actions.deleteReplySuccess(item.id));
    try {
      return await client.delete<any, { message: string }>(`/quick-replies/${replyId}`);
    } catch (err) {
      dispatch(actions.addReplySuccess({ data: item })); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
