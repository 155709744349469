import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Chat>();

const slice = createSlice({
  name: "chats",
  initialState: {
    activeChat: null as string | null,
    status: "idle" as LoadingStatus,
    loadingMessages: false,
    all: adapter.getInitialState({
      total: 0,
    }),
    messages: {} as Record<string, ChatMessage[]>,
  },
  reducers: {
    resetChats(state) {
      state.activeChat = null;
      state.status = "idle";
      adapter.removeAll(state.all);
      state.messages = {};
    },
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {},
    setLoadingMessages(state, { payload }: PayloadAction<boolean>) {
      state.loadingMessages = payload;
    },
    setActiveChat(state, { payload }: PayloadAction<string>) {
      state.activeChat = payload;
    },
    getChatSuccess: (state, action: PayloadAction<Chat[]>) => {
      state.status = "success";
      adapter.setAll(state.all, action.payload);
    },
    getMessagesSuccess: (state, { payload }: PayloadAction<{ messages: ChatMessage[]; chatId: string }>) => {
      state.loadingMessages = false;
      state.messages[payload.chatId] = payload.messages;
    },
    addMessage: (state, action: PayloadAction<ChatMessage>) => {
      if (state.messages[action.payload.to] !== undefined) {
        state.messages[action.payload.to].push(action.payload);
      }
    },
    removeMessage: (state, action: PayloadAction<ChatMessage>) => {
      if (state.messages[action.payload.to] !== undefined) {
        state.messages[action.payload.to] = state.messages[action.payload.to].filter((m) => m.id !== action.payload.id);
      }
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectChats = createSelector(
  (state: RootState) => state.chats.all,
  (all) => selectAll(all)
);

export const { setActiveChat, resetChats } = actions;
export const getChats: AsyncAction = (instanceId: number) => {
  return async (dispatch, getState) => {
    const { chats } = getState();
    if (chats.status === "success") return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<Chat>>(`/whatsapp-instances/${instanceId}/chats`);
      return dispatch(actions.getChatSuccess(res.data));
    } catch (err) {
      dispatch(actions.setStatus("failed"));
      throw dispatch(actions.hasError(err));
    }
  };
};

export const getMessages: AsyncAction = (instanceId: number, chatId: string) => {
  return async (dispatch, getState) => {
    const { chats } = getState();
    if (chats.messages[chatId] !== undefined) return;

    dispatch(actions.setLoadingMessages(true));
    try {
      const res = await client.get<any, ResultList<ChatMessage>>(
        `/whatsapp-instances/${instanceId}/chats/${chatId}/messages`
      );
      return dispatch(actions.getMessagesSuccess({ messages: res.data, chatId }));
    } catch (err) {
      dispatch(actions.setLoadingMessages(false));
      throw dispatch(actions.hasError(err));
    }
  };
};

export const sendTextMessage: AsyncAction = (message: ChatMessage) => {
  return async (dispatch, getState) => {
    const {
      chats,
      instances: { activeId: activeInstance },
    } = getState();
    if (chats.messages[message.to] === undefined || activeInstance === null) return;

    dispatch(actions.addMessage(message));
    try {
      await client.post<any, ResultList<ChatMessage>>(`/whatsapp-instances/${activeInstance}/send-text-message`, {
        to: message.to,
        body: message.body,
      });
    } catch (err) {
      dispatch(actions.removeMessage(message));
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
