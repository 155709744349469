// ----------------------------------------------------------------------

export default function Switch(theme) {
  const isLight = theme.palette.mode === "light";

  return {
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        },
        track: {
          opacity: 1,
          backgroundColor: theme.palette.grey[400],
        },
        switchBase: {
          left: 0,
          right: "auto",
          "&:not(:.Mui-checked)": {
            color: theme.palette.grey[isLight ? 100 : 300],
          },
          "&.Mui-checked.Mui-disabled, &.Mui-disabled": {
            color: theme.palette.grey[isLight ? 400 : 600],
          },
          "&.Mui-disabled+.MuiSwitch-track": {
            opacity: 1,
            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
          },
        },
      },
    },
  };
}
