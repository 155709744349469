import React, { FC } from "react";
import { Box, Typography, Paper, Stack } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { DropzoneProps, useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import { fData } from "../../utils/formatNumber";

const RootStyle = styled("div")(({ theme }: any) => ({
  width: "100%",
  height: "80px",
  margin: "auto",
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }: any) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create("opacity", {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

type Props = DropzoneProps & {
  error: boolean;
  file: any;
  sx?: any;
};

const UploadFile: FC<Props> = ({ error, file, sx, ...other }) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });
  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        my: 2,
        borderColor: "error.light",
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { name, size } = file;
        return (
          <Box key={name} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {name} - {fData(size)}
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - {e.message}
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
  return (
    <>
      <RootStyle sx={sx}>
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: "error.main",
              borderColor: "error.light",
              bgcolor: "error.lighter",
            }),
          }}
        >
          <input {...getInputProps()} />

          {file && (
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ gap: 1 }}>
              <span>
                {file.name}
              </span>
              <Box component={Icon} icon={"vscode-icons:file-type-excel"} sx={{ width: 24, height: 24 }} />
            </Stack>
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: "common.white",
                bgcolor: "grey.900",
                "&:hover": { opacity: 0.72 },
              }),
            }}
          >
            <Box component={Icon} icon={"solar:file-bold"} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{file ? "Update file" : "Upload file"}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}
    </>
  );
};

export default UploadFile;
