import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
// layouts
import MainLayout from "../layouts/MainLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import { PATH_DASHBOARD } from "./paths";

// ----------------------------------------------------------------------

const Loadable = (Component: LazyExoticComponent<any>) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// pages
const Login = Loadable(lazy(() => import("../pages/Login")));
const Register = Loadable(lazy(() => import("../pages/Register")));
const VerifyCode = Loadable(lazy(() => import("../pages/VerifyCode")));
const ResetPassword = Loadable(lazy(() => import("../pages/ResetPassword")));
const EmployeeList = Loadable(lazy(() => import("../pages/EmployeeList")));
const EmployeeCreate = Loadable(lazy(() => import("../pages/EmployeeCreate")));
const ReplyList = Loadable(lazy(() => import("../pages/ReplyList")));
const ReplyCreate = Loadable(lazy(() => import("../pages/ReplyCreate")));
const ChatBotList = Loadable(lazy(() => import("../pages/ChatBotList")));
const ChatBotInstances = Loadable(lazy(() => import("../pages/ChatBotInstances")));
const ChatBotCreate = Loadable(lazy(() => import("../pages/ChatBotCreate")));
const Chat = Loadable(lazy(() => import("../pages/Chat")));
const CategoryList = Loadable(lazy(() => import("../pages/CategoryList")));
const CategoryCreate = Loadable(lazy(() => import("../pages/CategoryCreate")));
const ContactList = Loadable(lazy(() => import("../pages/ContactList")));
const ContactCreate = Loadable(lazy(() => import("../pages/ContactCreate")));
const GroupList = Loadable(lazy(() => import("../pages/GroupList")));
const InstanceList = Loadable(lazy(() => import("../pages/InstanceList")));
const InstanceCreate = Loadable(lazy(() => import("../pages/InstanceCreate")));
const InstanceUpdate = Loadable(lazy(() => import("../pages/InstanceUpdate")));
const ProfileUpdate = Loadable(lazy(() => import("../pages/ProfileUpdate")));
const Page404 = Loadable(lazy(() => import("../pages/Page404")));
const TermsServices = Loadable(lazy(() => import("../pages/TermsServices")));
const PaymentPage = Loadable(lazy(() => import("../pages/PaymentPage")));
const PaymentStatus = Loadable(lazy(() => import("../pages/PaymentStatus")));
const InvoiceList = Loadable(lazy(() => import("../pages/InvoiceList")));
const Home = Loadable(lazy(() => import("../pages/Home")));

const Router: FC = () => {
  return useRoutes([
    // Main Routes
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "reset-password",
          element: (
            <GuestGuard>
              <ResetPassword />
            </GuestGuard>
          ),
        },
        {
          path: "verify",
          element: <VerifyCode />,
        },
      ],
    },
    // dashboard
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to={PATH_DASHBOARD.instances} replace /> },
        { path: PATH_DASHBOARD.profile, element: <ProfileUpdate /> },
        { path: "payment/:id", element: <PaymentPage /> },
        { path: "payment-status/:invoice_id", element: <PaymentStatus /> },
        { index: true, element: <Navigate to={PATH_DASHBOARD.employees} replace /> },
        { path: PATH_DASHBOARD.profile, element: <ProfileUpdate /> },
        {
          path: PATH_DASHBOARD.employees,
          children: [
            { index: true, element: <EmployeeList /> },
            { path: "new", element: <EmployeeCreate /> },
            { path: ":id/edit", element: <EmployeeCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.replies,
          children: [
            { index: true, element: <ReplyList /> },
            { path: "new", element: <ReplyCreate /> },
            { path: ":id/edit", element: <ReplyCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.chatBots,
          children: [
            { index: true, element: <ChatBotInstances /> },
            {path: ":instanceId/messages", element: <ChatBotList />},
            { path: ":instanceId/messages/new", element: <ChatBotCreate /> },
            { path: ":instanceId/messages/:id/edit", element: <ChatBotCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.chat.root,
          children: [{ index: true, element: <Chat /> }],
        },
        {
          path: PATH_DASHBOARD.categories,
          children: [
            { index: true, element: <CategoryList /> },
            { path: "new", element: <CategoryCreate /> },
            { path: ":id/edit", element: <CategoryCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.contacts,
          children: [
            { index: true, element: <ContactList /> },
            { path: "new", element: <ContactCreate /> },
            { path: ":id/edit", element: <ContactCreate /> },
          ],
        },
        {
          path: PATH_DASHBOARD.instances,
          children: [
            { index: true, element: <InstanceList /> },
            { path: "new", element: <InstanceCreate /> },
            { path: ":id/edit", element: <InstanceUpdate /> },
          ],
        },
        { path: PATH_DASHBOARD.groups, element: <GroupList /> },
        { path: PATH_DASHBOARD.invoices, element: <InvoiceList /> },
        { path: PATH_DASHBOARD.groups, element: <GroupList /> },
      ],
    },
    {
      path: "*",
      children: [
        { path: "404", element: <Page404 /> },
        { path: "terms-services", element: <TermsServices /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
};

export default Router;
