import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Package>();
const slice = createSlice({
  name: "packages",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getPackagesSuccess(state, action: PayloadAction<ResultList<Package>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      adapter.setAll(state, data);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectPackages = createSelector(
  (state: RootState) => state.packages,
  (packages) => selectAll(packages)
);

export const getPackages: AsyncAction = (page: number) => {
  return async (dispatch, getState) => {
    const { currentPage } = getState().packages;
    if (currentPage === page) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<Package>>("/packages", { params: { page } });
      return dispatch(actions.getPackagesSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
