// material
import { styled } from "@mui/material/styles";
import {Box, Card, Container, Link, Stack, Typography} from "@mui/material";
// routes
// hooks
// layouts
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import LoginForm from "../components/forms/LoginForm";
import {FC} from "react";
import {PATH_AUTH} from "../routes/paths";
import {Link as RouterLink} from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Login: FC = () => {
  return (
    <RootStyle title="Login">
      <AuthLayout>
        ليس لديك حساب؟&nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
          التسجيل الآن
        </Link>
      </AuthLayout>
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            اهلاً، مرحباً بعودتك
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                تسجيل الدخول إلى برق
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>ادخل بياناتك بالأسفل.</Typography>
            </Box>
          </Stack>

          <LoginForm />
          <MHidden width="smUp">
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              ليس لديك حساب؟&nbsp;
              <Link to={PATH_AUTH.register} component={RouterLink}>
                التسجيل الآن
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

export default Login;
