import { FC, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Stack, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { login } from "../../redux/slices/client";
import { PATH_AUTH } from "../../routes/paths";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});
const LoginForm: FC = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector((state) => state.client.status);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    resolver: yupResolver(LoginSchema),
  });

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  function handleLogin(credentials: LoginData) {
    dispatch(login(credentials)).catch(() => {});
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(handleLogin)}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="البريد الإلكتروني"
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? "text" : "password"}
          label="كلمة المرور"
          {...register("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox />} label="تذكرني" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          نسيت كلمة المرور ؟
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={status === "pending"}>
        تسجيل الدخول
      </LoadingButton>
    </form>
  );
};

export default LoginForm;
