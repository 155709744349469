import last from "lodash/last";
// material
import { Link as RouterLink } from "react-router-dom";
import { Box, Breadcrumbs, BreadcrumbsProps, Link, Typography } from "@mui/material";
import { FC, ReactNode } from "react";

// ----------------------------------------------------------------------

const Separator = <Box component="span" sx={{ width: 4, height: 4, borderRadius: "50%", bgcolor: "text.disabled" }} />;

type LinkType = { href?: string; name: string; icon?: ReactNode };
const LinkItem: FC<{ link: LinkType }> = ({ link }) => {
  const { href = "", name, icon } = link;
  return (
    <Link
      component={RouterLink}
      to={href}
      key={name}
      variant="body2"
      sx={{
        lineHeight: 2,
        display: "flex",
        alignItems: "center",
        color: "text.primary",
        "& > div": { display: "inherit" },
      }}
    >
      {icon && (
        <Box
          sx={{
            mr: 1,
            "& svg": { width: 20, height: 20 },
          }}
        >
          {icon}
        </Box>
      )}
      {name}
    </Link>
  );
};

type Props = BreadcrumbsProps & { links: LinkType[]; activeLast?: boolean };
const MBreadcrumbs: FC<Props> = ({ links, activeLast = false, ...other }) => {
  const currentLink = last(links)?.name;

  const listDefault = links.map((link, i) => <LinkItem link={link} />);
  const listActiveLast = links.map((link) => (
    <div key={link.name}>
      {link.name !== currentLink ? (
        <LinkItem link={link} />
      ) : (
        <Typography
          variant="body2"
          sx={{
            maxWidth: 260,
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "text.disabled",
            textOverflow: "ellipsis",
          }}
        >
          {currentLink}
        </Typography>
      )}
    </div>
  ));

  return (
    <Breadcrumbs separator={Separator} {...other}>
      {activeLast ? listDefault : listActiveLast}
    </Breadcrumbs>
  );
};

export default MBreadcrumbs;
