import client from "./axios";

async function sendSingleWaMsg(id: string, file: File, message: string) {
	let api = `/${id}/send-same-whatsapp-message-to-bulk-phones`;
	const formData = new FormData();
	formData.append("file", file);
	formData.append("message", message);

	const { data } = await client.post(api, formData);
	return data;
}

async function sendMultiWaMsgs(id: string, file: File) {
	let api = `/${id}/send-different-whatsapp-message-to-bulk-phones`;
	const formData = new FormData();
	formData.append("file", file);
	const { data } = await client.post(api, formData);
	return data;
}

const whatsappMsgAPI = {
	sendSingleWaMsg,
	sendMultiWaMsgs
};

export default whatsappMsgAPI;
