import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  FormControlLabel,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import validationMsg from "../../data/validation";
import { hideWhatsappMsg } from "../../redux/slices/whatsapp";
import whatsappMsgAPI from "../../utils/whatsappMsgApi";
import UploadFile from "../upload/UploadFile";
import { addAlert } from "../../redux/slices/alerts";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { selectAvaiableInstance } from "../../redux/slices/instances";
import LoadingButton from "@mui/lab/LoadingButton";

interface FormProps { }

interface FormInputs {
  file: File;
  message: string;
  whatsapp_instance_id: string;
}

enum Forms {
  singleMsg,
  multiMsg,
}

const SendWhastsApp: FC<FormProps> = () => {
  const [currForm, setCurrForm] = useState(Forms.singleMsg);
  const isActive = useAppSelector((state) => state.whatsappMsgs.isActive);
  const instances = useAppSelector(selectAvaiableInstance);
  const dispatch = useAppDispatch();

  const schema = Yup.object().shape({
    file: Yup.mixed().required(validationMsg.required),
    whatsapp_instance_id: Yup.string().required(validationMsg.required),
    ...(currForm === Forms.singleMsg
      ? {
        message: Yup.string().required(validationMsg.required),
      }
      : {}),
  });

  const {
    register,
    control,
    formState: { errors, isSubmitted },
    handleSubmit,
    reset,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  function handleFormSubmitSuccess() {
    setIsLoading(false);
    closeForm();
    dispatch(addAlert({ message: "تم إرسال الرسائل بنجاح", variant: "success" }));
  }

  function handleFormSubmitError() {
    setIsLoading(false);
    dispatch(addAlert({ message: "حدث خطأ أثناء إرسال الرسائل", variant: "error" }));
  }

  function onSingleMsgSubmit(data: FormInputs) {
    setIsLoading(true);
    whatsappMsgAPI
      .sendSingleWaMsg(data.whatsapp_instance_id, data.file, data.message)
      .then(handleFormSubmitSuccess)
      .catch(handleFormSubmitError);
  }

  function onMultiMsgSubmit(data: FormInputs) {
    setIsLoading(true);
    whatsappMsgAPI
      .sendMultiWaMsgs(data.whatsapp_instance_id, data.file)
      .then(handleFormSubmitSuccess)
      .catch(handleFormSubmitError);
  }

  function changeForm(form: Forms) {
    setCurrForm(form);
    reset();
  }

  function closeForm() {
    dispatch(hideWhatsappMsg());
    reset();
  }

  const submitHandler = currForm === Forms.singleMsg ? onSingleMsgSubmit : onMultiMsgSubmit

  return (
    <Dialog open={isActive} onClose={closeForm} maxWidth="sm" fullWidth>
      <DialogTitle>إرسال رسالة نصية</DialogTitle>
      <RadioGroup
        onChange={(e) => changeForm(parseInt(e.target.value))}
        value={currForm}
      >
        <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 2 }}>
          <FormControlLabel value={Forms.singleMsg} control={<Radio />} label="رسالة موحدة لكل العملاء" />
          <FormControlLabel value={Forms.multiMsg} control={<Radio />} label="رسالة مخصصة لكل عميل" />
        </Stack>
      </RadioGroup>
      <form noValidate onSubmit={handleSubmit(submitHandler)}>
        <DialogContent>
          <Stack sx={{ gap: 2 }}>
            <FormControl fullWidth error={!!errors.whatsapp_instance_id}>
              <InputLabel id="whatsapp_instance_id">الجهاز</InputLabel>
              <Controller
                name="whatsapp_instance_id"
                control={control}
                render={({ field: { value = "", ...rest } }) => (
                  <Select labelId="whatsapp_instance_id" label="الجهاز" value={value} {...rest}>
                    {instances.map((instance) => (
                      <MenuItem key={instance.id} value={instance.id}>
                        {instance.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.whatsapp_instance_id && <FormHelperText>{errors.whatsapp_instance_id.message}</FormHelperText>}
            </FormControl>

            <FormControl error={!!errors.file}>
              <Box position="relative" style={{ display: "flex", gap: 4 }}>
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <UploadFile
                        accept=".xlsx"
                        file={value}
                        onDrop={(acceptedFiles) => {
                          const file = acceptedFiles[0];
                          if (file) {
                            onChange(file);
                          };
                        }}
                        error={Boolean(isSubmitted && errors.file)}
                      />
                    )
                  }

                  }
                />
              </Box>
              <FormHelperText>{errors.file?.message}</FormHelperText>
            </FormControl>
            {currForm === Forms.singleMsg && (
              <FormControl fullWidth error={!!errors.message}>
                <Box position="relative">
                  <TextField
                    id="outlined-multiline-static"
                    label="الرسالة"
                    multiline
                    style={{ minWidth: '100%', maxWidth: '100%' }}
                    {...register("message", {
                      required: validationMsg["required"],
                    })}
                  />
                </Box>
                {errors.message && <FormHelperText>{errors.message?.message}</FormHelperText>}
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
            إرسال
          </LoadingButton>
          <Button variant="outlined" onClick={closeForm}>
            إلغاء
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SendWhastsApp;
