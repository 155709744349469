import { FC, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
// hooks
// pages
import Login from "../pages/Login";
import { useAppSelector } from "../hooks/redux";
import { PATH_AUTH } from "../routes/paths";

// ----------------------------------------------------------------------

const AuthGuard: FC = ({ children }) => {
  const client = useAppSelector((state) => state.client);
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!client.token) {
    if (pathname !== requestedLocation) setRequestedLocation(pathname);
    return <Login />;
  }

  if (client.current?.status === 2) return <Navigate to={PATH_AUTH.verify} replace />;

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} replace />;
  }

  return <>{children}</>;
};

export default AuthGuard;
