import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<WInstance>({
  sortComparer: (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at),
});
const slice = createSlice({
  name: "instances",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    activeId: null as number | null,
    mutatingId: null as number | null,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    setActiveId(state, { payload }: PayloadAction<number | null>) {
      state.activeId = payload;
    },
    setMutatingId(state, { payload }: PayloadAction<number | null>) {
      state.mutatingId = payload;
    },
    getInstancesSuccess(state, action: PayloadAction<ResultList<WInstance>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      // set active instance on fetch if it wasn't set before
      if (state.activeId === null) {
        const active = data.find((i) => Boolean(i.token));
        if (active) state.activeId = active.id;
      }
      adapter.setAll(state, data);
    },
    addInstanceSuccess(state, action: PayloadAction<Result<WInstance>>) {
      state.status = "success";
      state.mutatingId = null;
      adapter.upsertOne(state, action.payload.data);
    },
    changeInstance(state, action: PayloadAction<{ id: number; changes: Partial<WInstance> }>) {
      adapter.updateOne(state, action.payload);
    },
    toggleAutoAnswer(state, action: PayloadAction<number>) {
      const instance = state.entities[action.payload];
      if (instance === undefined) return;

      instance.auto_answer_on_received = instance.auto_answer_on_received === 1 ? 0 : 1;
    },
    deleteInstanceSuccess(state, action: PayloadAction<number>) {
      adapter.removeOne(state, action.payload);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const { setActiveId: setActiveInstance, changeInstance } = actions;
export const selectInstances = createSelector(
  (state: RootState) => state.instances,
  (instances) => selectAll(instances)
);

export const selectActiveInstances = createSelector(
  (state: RootState) => state.instances,
  (instances) => selectAll(instances).filter((instance) => !!instance.token)
);

export const selectAvaiableInstance = createSelector(
  (state: RootState) => state.instances,
  (instances) => {
    return selectAll(instances).filter((instance) => instance.token && instance.phone);
  }
);

export const getInstances: AsyncAction = (page: number, force = false) => {
  return async (dispatch, getState) => {
    const { currentPage, status } = getState().instances;
    if (currentPage === page && !force) return;

    if (currentPage !== page && status !== "success") dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<WInstance>>("/whatsapp-instances", { params: { page } });
      return dispatch(actions.getInstancesSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const showInstance: AsyncAction = (id: number, forceRefetch?: boolean) => {
  return async (dispatch, getState) => {
    const item = getState().instances.entities[id];
    if (item !== undefined && !forceRefetch) return;

    if (forceRefetch) {
      // mutatingId
      dispatch(actions.setMutatingId(id));
      dispatch(actions.setStatus("mutating"));
    } else {
      dispatch(actions.setStatus("pending"));
    }
    try {
      const res = await client.get<any, Result<WInstance>>(`/whatsapp-instances/${id}`);
      return dispatch(actions.addInstanceSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const storeInstance: AsyncAction = (data: CreateWInstance) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.post<any, Result<WInstance & { invoice: Invoice }>>("/whatsapp-instances/", data);
      dispatch(actions.setStatus("success"));
      return res;
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const updateInstance: AsyncAction = ({ id, ...patch }: UpdateWInstance & Pick<WInstance, "id">) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.put<any, Result<WInstance>>(`/whatsapp-instances/${id}`, patch);
      return dispatch(actions.addInstanceSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const deleteInstance: AsyncAction = (instanceId: number) => {
  return async (dispatch, getState) => {
    const item = getState().instances.entities[instanceId];
    if (item === undefined) return;

    dispatch(actions.deleteInstanceSuccess(instanceId));
    try {
      return await client.delete<any, { message: string }>(`/whatsapp-instances/${instanceId}`);
    } catch (err) {
      dispatch(actions.addInstanceSuccess({ data: item })); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export const toggleInstanceAutoAnswer: AsyncAction = (instanceId: number) => {
  return async (dispatch, getState) => {
    const item = getState().instances.entities[instanceId];
    if (item === undefined) return;

    dispatch(actions.toggleAutoAnswer(instanceId));

    try {
      return await client.post<any, Result<WInstance>>(
        `/whatsapp-instances/${instanceId}/${item.auto_answer_on_received ? "disable" : "enable"}-auto-answer`
      );
    } catch (err) {
      dispatch(actions.toggleAutoAnswer(instanceId)); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
