// routes
import {PATH_DASHBOARD} from "../routes/paths";
import SvgIconStyle from "../components/SvgIconStyle";
import {People, Receipt} from "@mui/icons-material";

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{width: "100%", height: "100%"}}/>
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  people: <People/>,
  invoice: <Receipt/>,
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  booking: getIcon("ic_booking"),
  whatsapp: getIcon("whatsapp"),
};

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'إدارة',
    items: [
      {
        title: "الأجهزة",
        path: PATH_DASHBOARD.instances,
        icon: ICONS.whatsapp,
      },
      {
        title: "المحادثات",
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
      {
        title: "الفواتير",
        path: PATH_DASHBOARD.invoices,
        icon: ICONS.invoice,
      },
      // {
      //   title: "الموظفين",
      //   path: PATH_DASHBOARD.employees,
      //   icon: ICONS.user,
      // },
      {
        title: "الردود السريعة",
        path: PATH_DASHBOARD.replies,
        icon: ICONS.chat,
      },
      {
        title: "البوت",
        path: PATH_DASHBOARD.chatBots,
        icon: ICONS.dashboard,
      },
      {
        title: "التصنيفات",
        path: PATH_DASHBOARD.categories,
        icon: ICONS.analytics,
      },
      {
        title: "مجموعات الأرقام",
        path: PATH_DASHBOARD.groups,
        icon: ICONS.user,
      },
      {
        title: "جهات الإتصال",
        path: PATH_DASHBOARD.contacts,
        icon: ICONS.people,
      },
    ],
  },
];

export default sidebarConfig;
