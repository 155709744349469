import { Navigate } from "react-router-dom";
// hooks
// routes
import { PATH_DASHBOARD } from "../routes/paths";
import { useAppSelector } from "../hooks/redux";
import { FC } from "react";

// ----------------------------------------------------------------------

const GuestGuard: FC = ({ children }) => {
  const token = useAppSelector((state) => state.client.token);

  if (token !== null) return <Navigate to={PATH_DASHBOARD.root} replace />;

  return <>{children}</>;
};

export default GuestGuard;
