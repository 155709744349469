import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "settings",
  initialState: {
    themeMode: localStorage.getItem("themeMode") || ("light" as "light" | "dark"),
    themeDirection: "rtl" as "ltr" | "rtl",
  },
  reducers: {
    changeMode(state, action: PayloadAction<"light" | "dark">) {
      state.themeMode = action.payload;
    },
    changeDirection(state, action: PayloadAction<"ltr" | "rtl">) {
      state.themeDirection = action.payload;
    },
  },
});

export const { changeMode } = slice.actions;
export default slice.reducer;
