import { FC, useEffect } from "react";
import { useSnackbar } from "notistack";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { closeAlert, removeAlert } from "../redux/slices/alerts";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { MIconButton } from "./@material-extend";

const displayed: string[] = [];
const AlertsSystem: FC = () => {
  const dispatch = useAppDispatch();
  const alerts = useAppSelector((state) => state.alerts);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function handleClose(id: string) {
    dispatch(closeAlert(id));
  }

  function handleRemove(id: string) {
    dispatch(removeAlert(id));
  }

  useEffect(() => {
    alerts.forEach(({ id, variant, message, dismissed }) => {
      if (dismissed) {
        closeSnackbar(id);
        return;
      }

      if (displayed.includes(id)) return;

      enqueueSnackbar(message, {
        key: id,
        autoHideDuration: 6000,
        variant,
        onExited(evt, key) {
          handleRemove(key as string);
          displayed.splice(displayed.indexOf(key as string), 1);
        },
        action: (key) => (
          <MIconButton size="small" onClick={() => handleClose(id)}>
            <Icon icon={closeFill} />
          </MIconButton>
        ),
      });
      displayed.push(id);
    });
  }, [alerts]); // eslint-disable-line

  return null;
};

export default AlertsSystem;
