import { Icon } from "@iconify/react";
import moonFill from "@iconify/icons-eva/moon-fill";
import sunFill from "@iconify/icons-eva/sun-fill";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
// hooks
import useCollapseDrawer from "../hooks/useCollapseDrawer";
// components
import { MHidden } from "../components/@material-extend";
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { changeMode } from "../redux/slices/settings";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type Props = { onOpenSidebar: () => void };
const Navbar: FC<Props> = ({ onOpenSidebar }) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector((state) => state.settings.themeMode);
  const { isCollapse } = useCollapseDrawer();

  function toggleMode() {
    const updated = mode === "light" ? "dark" : "light";
    dispatch(changeMode(updated));
    localStorage.setItem("themeMode", updated);
  }

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <IconButton onClick={toggleMode} aria-label="Toggle mode">
            <Icon icon={mode === "light" ? moonFill : sunFill} width={20} height={20} />
          </IconButton>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
};

export default Navbar;
