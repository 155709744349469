import * as yup from "yup";
import AlertsSystem from "./components/AlertsSystem";
import ThemeConfig from "./theme";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/ProgressBar";
import Router from "./routes";
import validation from "./data/validation";

// custom locale dictionary for yup
yup.setLocale({
  mixed: { required: validation.required },
  string: { email: validation.invalidEmail, url: validation.invalidUrl },
});
// custom method for yup (allow empty string)
yup.addMethod(yup.mixed, "optional", function () {
  return this.transform((value) => {
    if (value === undefined || value === "") return undefined;
    return value;
  });
});

function App() {
  return (
    <ThemeConfig>
      <NotistackProvider>
        <AlertsSystem />
        <ProgressBarStyle />
        <Router />
      </NotistackProvider>
    </ThemeConfig>
  );
}

export default App;
