import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const slice = createSlice({
  name: "client",
  initialState: {
    status: "idle" as LoadingStatus,
    current: null as Client | null,
    token: localStorage.getItem("token"),
  },
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    loginSuccess(state, action: PayloadAction<Result<Client> & { token: string }>) {
      state.status = "success";
      state.token = action.payload.token;
      state.current = action.payload.data;
    },
    logoutSuccess(state) {
      state.status = "idle";
      state.token = null;
      state.current = null;
    },
    addProfileSuccess(state, action: PayloadAction<Client>) {
      state.status = "success";
      state.current = action.payload;
    },
    updateClient(state, action: PayloadAction<Partial<Client>>) {
      state.current = Object.assign({}, state.current, action.payload);
    },
  },
});

const { actions, reducer } = slice;
export const { logoutSuccess } = actions;

export const login: AsyncAction = (credentials: LoginData) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.post<any, Result<Client> & { token: "string" }>("/login", credentials);
      localStorage.setItem("token", res.token);
      return dispatch(actions.loginSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const activate: AsyncAction = (data: { username: string; code: string }) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.post<null, Result<Client> & { token: string }>("/activate", data);
      localStorage.setItem("token", res.token);
      return dispatch(actions.loginSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const logout: AsyncAction = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    dispatch(actions.logoutSuccess());
    return client.post<any, { message: "string" }>("/logout");
  };
};

export const showProfile: AsyncAction = () => {
  return async (dispatch, getState) => {
    if (getState().client.current !== null) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, Result<Client>>("/show-profile");
      return dispatch(actions.addProfileSuccess(res.data));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const updateProfile: AsyncAction = (data: StoreClient) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.post<any, Result<Client>>("/update-profile", data);
      return dispatch(actions.addProfileSuccess(res.data));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
