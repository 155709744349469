import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<ChatBot>();
const slice = createSlice({
  name: "chatBots",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
    whatsapp_instance_id: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getChatBotsSuccess(state, action: PayloadAction<ChatBotList>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      state.whatsapp_instance_id = meta.whatsapp_instance_id;
      adapter.setAll(state, data);
    },
    addChatBotSuccess(state, action: PayloadAction<Result<ChatBot>>) {
      state.status = "success";
      const newEntity = action.payload.data;

      // if it's already exists, update it without change its position
      if (state.entities[newEntity.id] !== undefined) {
        adapter.upsertOne(state, newEntity);
        return;
      }

      // Get the current entities array
      const { selectAll } = adapter.getSelectors();
      const currentEntities = selectAll(state);

      // Add the new entity at the first index
      const updatedEntities = [newEntity, ...currentEntities];

      // Update the state with the new entities array
      adapter.setAll(state, updatedEntities);
    },
    deleteChatBotSuccess(state, action: PayloadAction<number>) {
      adapter.removeOne(state, action.payload);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectChatBots = createSelector(
  (state: RootState) => state.chatBots,
  (chatBots) => selectAll(chatBots)
);

export const getChatBots: AsyncAction = (page: number, whatsapp_instance_id: number) => {
  return async (dispatch, getState) => {
    const currentState = getState().chatBots;
    if (currentState.currentPage === page && currentState.whatsapp_instance_id === whatsapp_instance_id) {
      return;
    }

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<ChatBot>>("/chat-bots", { params: { page, whatsapp_instance_id } });
      return dispatch(
        actions.getChatBotsSuccess({
          ...res,
          meta: {
            ...res.meta,
            whatsapp_instance_id,
          },
        })
      );
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const showChatBot: AsyncAction = (id: number) => {
  return async (dispatch, getState) => {
    if (getState().chatBots.entities[id] !== undefined) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, Result<ChatBot>>(`/chat-bots/${id}`);
      return dispatch(actions.addChatBotSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const storeChatBot: AsyncAction = (data: StoreChatBot) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.post<any, Result<ChatBot>>("/chat-bots/", data);
      return dispatch(actions.addChatBotSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const updateChatBot: AsyncAction = ({ id, ...patch }: StoreChatBot & Pick<ChatBot, "id">) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.put<any, Result<ChatBot>>(`/chat-bots/${id}`, patch);
      return dispatch(actions.addChatBotSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

// optimistic update to toggle chatbot status 1 | 0
export const toggleChatBotStatus: AsyncAction = (chatBotId: number) => {
  return async (dispatch, getState) => {
    const item = getState().chatBots.entities[chatBotId];
    if (item === undefined) return;

    dispatch(actions.addChatBotSuccess({ data: { ...item, status: (item.status ? 0 : 1) as ChatBot["status"] } }));

    try {
      const updateData: StoreChatBot & Pick<ChatBot, "id"> = {
        id: item.id,
        upcoming_message: item.upcoming_message,
        upcoming_message_type: item.upcoming_message_type,
        reply_message: item.reply_message,
        reply_message_type: item.reply_message_type,
        whatsapp_instance_id: item.whatsapp_instance.id,
        status: (item.status ? 0 : 1) as ChatBot["status"],
      };
      return await client.put<any, Result<ChatBot>>(`/chat-bots/${chatBotId}`, updateData);
    } catch (err) {
      dispatch(actions.addChatBotSuccess({ data: item })); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export const deleteChatBot: AsyncAction = (chatBotId: number) => {
  return async (dispatch, getState) => {
    const item = getState().chatBots.entities[chatBotId];
    if (item === undefined) return;

    dispatch(actions.deleteChatBotSuccess(item.id));
    try {
      return await client.delete<any, { message: string }>(`/chat-bots/${chatBotId}`);
    } catch (err) {
      dispatch(actions.addChatBotSuccess({ data: item })); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
