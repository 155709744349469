import { useAppDispatch, useAppSelector } from "./redux";
import { hoverEnter, hoverLeave, toggleCollapse } from "../redux/slices/collapseDrawer";

const useCollapseDrawer = () => {
  const dispatch = useAppDispatch();
  const { collapseClick, collapseHover } = useAppSelector((state) => state.collapseDrawer);
  const isCollapse = collapseClick && !collapseHover;
  // actions
  const onToggleCollapse = () => {
    dispatch(toggleCollapse());
  };
  const onHoverEnter = () => {
    if (collapseClick) dispatch(hoverEnter());
  };
  const onHoverLeave = () => {
    dispatch(hoverLeave());
  };
  return { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave };
};

export default useCollapseDrawer;
