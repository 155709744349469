import { useMemo } from "react";
// material
import { CssBaseline } from "@mui/material";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { arEG } from "@mui/material/locale";
// hooks
//
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import GlobalStyles from "./GlobalStyles";
import { useAppSelector } from "../hooks/redux";
import RtlLayout from "./RtlLayout";

// ----------------------------------------------------------------------

export default function ThemeConfig({ children }) {
  const { themeMode, themeDirection } = useAppSelector((state) => state.settings);
  const isLight = themeMode === "light";

  const theme = useMemo(() => {
    const theme = createTheme(
      {
        palette: isLight ? { ...palette.light, mode: "light" } : { ...palette.dark, mode: "dark" },
        shape,
        typography,
        breakpoints,
        direction: themeDirection,
        shadows: isLight ? shadows.light : shadows.dark,
        customShadows: isLight ? customShadows.light : customShadows.dark,
      },
      arEG
    );
    theme.components = componentsOverride(theme);
    return theme;
  }, [isLight, themeDirection]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RtlLayout>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </RtlLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
