import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Permission>();
const slice = createSlice({
  name: "permissions",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    startLoading(state, { payload = "pending" }: PayloadAction<LoadingStatus | undefined>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getPermissionsSuccess(state, action: PayloadAction<ResultList<Permission>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      adapter.setAll(state, data);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectPermissions = createSelector(
  (state: RootState) => state.permissions,
  (permissions) => selectAll(permissions)
);

export const getPermissions: AsyncAction = (page: number) => {
  return async (dispatch, getState) => {
    if (getState().permissions.currentPage === page) return;

    dispatch(actions.startLoading());
    try {
      const res = await client.get<any, ResultList<Permission>>("/permissions", { params: { page } });
      return dispatch(actions.getPermissionsSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
