import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
	name: "whatsappMsg",
	initialState: { isActive: false },
	reducers: {
		showWhatsappMsg: (state) => ({ isActive: true }),
		hideWhatsappMsg: (state) => ({ isActive: false }),
	},
});

export const { showWhatsappMsg, hideWhatsappMsg } = slice.actions;

export default slice.reducer;
