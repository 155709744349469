import { combineReducers } from "@reduxjs/toolkit";
import client from "./slices/client";
import alerts from "./slices/alerts";
import employees from "./slices/employees";
import replies from "./slices/replies";
import chatBots from "./slices/chatBots";
import permissions from "./slices/permissions";
import settings from "./slices/settings";
import collapseDrawer from "./slices/collapseDrawer";
import categories from "./slices/categories";
import contacts from "./slices/contacts";
import groups from "./slices/groups";
import instances from "./slices/instances";
import chats from "./slices/chats";
import invoices from "./slices/invoices";
import whatsappMsgs from "./slices/whatsapp";
import packages from "./slices/packages";

const appReducer = combineReducers({
  client,
  alerts,
  employees,
  replies,
  permissions,
  chatBots,
  settings,
  collapseDrawer,
  categories,
  groups,
  contacts,
  instances,
  chats,
  invoices,
  whatsappMsgs,
  packages
});

const rootReducer = (state: any, action: any) => {
  // reset state on logout
  // ref: https://stackoverflow.com/a/35641992
  if (action.type === "client/logoutSuccess") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
