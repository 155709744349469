import { Middleware } from "@reduxjs/toolkit";
import { addAlert } from "../slices/alerts";
import { logoutSuccess } from "../slices/client";

const handleErrors: Middleware = () => (next) => (action) => {
  if (action.type.endsWith("/hasError")) {
    const { payload = {} } = action;
    if (typeof payload.data?.errors === "object") {
      Object.values(payload.data.errors).forEach((err: any) => {
        let message: string = err;
        if (Array.isArray(message)) message = message.join(", ");
        next(addAlert({ message, variant: "error" }));
      });
    } else {
      let message: string = payload.data?.message;
      if (payload.status === 500) message = "خطأ في الخادم";
      if (payload.status === 401) {
        message = "يرجى تسجل الدخول للاستمرار";
        localStorage.removeItem("token");
        next(logoutSuccess()); // logout on 401 errors
      } else if (payload.status === 0) message = "خطأ في الإتصال بالإنترنت";
      next(addAlert({ message, variant: "error" }));
    }
  }

  return next(action);
};

export default handleErrors;
