import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import handleErrors from "./milddlewares/handleErrors";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(handleErrors),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
