import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Employee>({
  sortComparer: (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at),
});
const slice = createSlice({
  name: "employees",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getEmployeesSuccess(state, action: PayloadAction<ResultList<Employee>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      adapter.setAll(state, data);
    },
    addEmployeeSuccess(state, action: PayloadAction<Result<Employee>>) {
      state.status = "success";
      adapter.upsertOne(state, action.payload.data);
    },
    deleteEmployeeSuccess(state, action: PayloadAction<number>) {
      adapter.removeOne(state, action.payload);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectEmployees = createSelector(
  (state: RootState) => state.employees,
  (employees) => selectAll(employees)
);

export const getEmployees: AsyncAction = (page: number) => {
  return async (dispatch, getState) => {
    if (getState().employees.currentPage === page) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<Employee>>("/employees", { params: { page } });
      return dispatch(actions.getEmployeesSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const showEmployee: AsyncAction = (id: number) => {
  return async (dispatch, getState) => {
    const item = getState().employees.entities[id];
    if (item !== undefined && item.permissions !== undefined) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, Result<Employee>>(`/employees/${id}`);
      return dispatch(actions.addEmployeeSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const storeEmployee: AsyncAction = (data: StoreEmployee) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.post<any, Result<Employee>>("/employees/", data);
      return dispatch(actions.addEmployeeSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const updateEmployee: AsyncAction = ({ id, ...patch }: StoreEmployee & Pick<Employee, "id">) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.put<any, Result<Employee>>(`/employees/${id}`, patch);
      return dispatch(actions.addEmployeeSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const deleteEmployee: AsyncAction = (employeeId: number) => {
  return async (dispatch, getState) => {
    const item = getState().employees.entities[employeeId];
    if (item === undefined) return;

    dispatch(actions.deleteEmployeeSuccess(employeeId));
    try {
      return await client.delete<any, { message: string }>(`/employees/${employeeId}`);
    } catch (err) {
      dispatch(actions.addEmployeeSuccess({ data: item })); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
