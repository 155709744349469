import {forwardRef} from "react";
// material
import {IconButton} from "@mui/material";
//
import ButtonAnimate from "../animate/ButtonAnimate";

// ----------------------------------------------------------------------

const MIconButton = forwardRef<any, any>(({ children, ...other }, ref) => (
  <ButtonAnimate>
    <IconButton ref={ref} {...other}>
      {children}
    </IconButton>
  </ButtonAnimate>
));

export default MIconButton;
