import { FC, ReactNode } from "react";
import { motion } from "framer-motion";
// material
import { Box } from "@mui/material";
//
import { varMediumClick, varSmallClick } from "./variants";

// ----------------------------------------------------------------------

type Props = { mediumClick?: boolean; children: ReactNode; sx?: any };
const ButtonAnimate: FC<Props> = ({ mediumClick = false, children, sx, ...other }) => {
  return (
    <Box
      component={motion.div}
      whileTap="tap"
      whileHover="hover"
      variants={mediumClick ? varMediumClick : varSmallClick}
      sx={{ display: "inline-flex", ...sx }}
      {...other}
    >
      {children}
    </Box>
  );
};

export default ButtonAnimate;
