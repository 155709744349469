const validation = {
  required: "هذا الحقل مطلوب",
  invalidEmail: "الرجاء إدخال بريد إلكتروني صالح",
  invalidPhone: "الرجاء إدخال رقم جوال صالح",
  invalidUrl: "الرجاء إدخال رابط صالح",
  shortPw: "كلمة المرور قصيرة جدا",
  notSamePw: "كلمات المرور غير متطابقة",
  numOnly: "هذا الحقل يجب ان يحتوي على رقم فقط",
};

export default validation;
