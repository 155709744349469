import axios from "axios";

const ROOT_URL = process.env.REACT_APP_API_URL as string;
if (!ROOT_URL) console.error("ROOT_URL is not defined!");

const client = axios.create({
  baseURL: ROOT_URL + "/client",
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) Object.assign(config.headers, { Authorization: `Bearer ${token}` });
  return config;
});

client.interceptors.response.use(
  (res) => res.data,
  (error) => Promise.reject({ data: error.response?.data, status: error.request.status })
);

export default client;
