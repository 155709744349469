import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AlertProps } from "@mui/material";
import uniqueId from "lodash/uniqueId";

type Alert = {
  id: string;
  dismissed: boolean;
  message: string;
  variant?: AlertProps["severity"];
};
const slice = createSlice({
  name: "alerts",
  initialState: [] as Alert[],
  reducers: {
    addAlert(state, action: PayloadAction<Omit<Alert, "id" | "dismissed">>) {
      state.push({ id: uniqueId(), dismissed: false, ...action.payload });
    },
    closeAlert(state, action: PayloadAction<string>) {
      const target = state.find(({ id }) => id === action.payload);
      if (target) target.dismissed = true;
    },
    removeAlert(state, action: PayloadAction<string>) {
      return state.filter(({ id }) => id !== action.payload);
    },
  },
});

export default slice.reducer;
export const { addAlert, closeAlert, removeAlert } = slice.actions;
