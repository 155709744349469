import { createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import client from "../../utils/axios";

const adapter = createEntityAdapter<Contact>();
const slice = createSlice({
  name: "contacts",
  initialState: adapter.getInitialState({
    status: "idle" as LoadingStatus,
    total: 0,
    pagesCount: 1,
    currentPage: null as number | null,
  }),
  reducers: {
    setStatus(state, { payload }: PayloadAction<LoadingStatus>) {
      state.status = payload;
    },
    hasError(state, action) {
      state.status = "failed";
    },
    getContactsSuccess(state, action: PayloadAction<ResultList<Contact>>) {
      const { meta, data } = action.payload;
      state.status = "success";
      state.total = meta.total;
      state.pagesCount = meta.last_page;
      state.currentPage = meta.current_page;
      adapter.setAll(state, data);
    },
    addContactSuccess(state, action: PayloadAction<Result<Contact>>) {
      state.status = "success";
      adapter.upsertOne(state, action.payload.data);
    },
    deleteContactSuccess(state, action: PayloadAction<number>) {
      adapter.removeOne(state, action.payload);
    },
  },
});

const { actions, reducer } = slice;

const { selectAll } = adapter.getSelectors();
export const selectContacts = createSelector(
  (state: RootState) => state.contacts,
  (contacts) => selectAll(contacts)
);

export const getContacts: AsyncAction = (page: number) => {
  return async (dispatch, getState) => {
    if (getState().contacts.currentPage === page) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, ResultList<Contact>>("/contacts", { params: { page } });
      return dispatch(actions.getContactsSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const showContact: AsyncAction = (id: number) => {
  return async (dispatch, getState) => {
    if (getState().contacts.entities[id] !== undefined) return;

    dispatch(actions.setStatus("pending"));
    try {
      const res = await client.get<any, Result<Contact>>(`/contacts/${id}`);
      return dispatch(actions.addContactSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const storeContact: AsyncAction = (data: StoreContact) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.post<any, Result<Contact>>("/contacts/", data);
      return dispatch(actions.addContactSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const updateContact: AsyncAction = ({ id, ...patch }: StoreContact & Pick<Contact, "id">) => {
  return async (dispatch) => {
    dispatch(actions.setStatus("mutating"));
    try {
      const res = await client.put<any, Result<Contact>>(`/contacts/${id}`, patch);
      return dispatch(actions.addContactSuccess(res));
    } catch (err) {
      throw dispatch(actions.hasError(err));
    }
  };
};

export const deleteContact: AsyncAction = (contactId: number) => {
  return async (dispatch, getState) => {
    const item = getState().contacts.entities[contactId];
    if (item === undefined) return;

    dispatch(actions.deleteContactSuccess(item.id));
    try {
      return await client.delete<any, { message: string }>(`/contacts/${contactId}`);
    } catch (err) {
      dispatch(actions.addContactSuccess({ data: item })); // put it pack instead of errors on deleting
      throw dispatch(actions.hasError(err));
    }
  };
};

export default reducer;
